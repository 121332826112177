import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"

export default props => {
  const {
    description,
    name,
    slug,
    summary,
    image,
    attribution
  } = props.data.item

  let attribution_rendered;


  if (attribution) {
    attribution_rendered = <div dangerouslySetInnerHTML={{__html: attribution.join("</br>") }} />
  }

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
	image={image.childImageSharp.GatsbyImageSharpFluid_withWebp}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="flex flex-wrap">            
	    <div className="w-full lg:w-2/3 pb-8">
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt={name}
                />
            {attribution_rendered}    
            </div>
            <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
                {name}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                {summary}
              </h2>
                <div className="my-4 text-base text-gray-700 whitespace-pre-line" dangerouslySetInnerHTML={{__html: description}} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query homophoneQuery($slug: String!) {
     item: homophonesJson(slug: { eq:$slug }) {
         description
	 name
	 slug
	 summary
	 image {
	childImageSharp {
          fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
	}
	 }
   attribution
     }
  }`
